<template>
  <div id="app">
    <b-container fluid>
      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p>HEX code copied to clipboard!</p>
      </b-alert>
      <b-modal
        ref="pass-modal"
        centered
        hide-header
        hide-footer
        hide-header-close
        no-close-on-backdrop
        size="sm"
        title="Enter password..."
      >
        <b-form-input v-model="password" type="password" placeholder="Enter password..."></b-form-input>
        <b-button
          class="mt-2"
          variant="outline-success"
          block
          @click="fetchPantones()"
          >{{ submitButton }}</b-button
        >
      </b-modal>
      <b-row>
        <b-col cols="10"
          ><h3 class="text-left" style="padding: 5px 0 0 10px; color: rgb(38, 78, 137);">
            SonyDADC Pantone Matching System v{{ version }}
          </h3></b-col
        >
        <b-col cols="2" class="my-1">
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              placeholder="Search..."
              type="search"
              debounce="1000"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          responsive
          striped
          hover
          no-border-collapse
          sticky-header="93vh"
          :items="pantones"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
        >
          <template #cell(charged)="data">
            {{ data.item.system == "Pantone Solid Coated" ? "No" : "Yes" }}
          </template>
          <template #cell(hex)="data">
            <b
              style="
                color: white;
                padding: 5px 15px;
                text-shadow: 0px 0px 3px #000;
                cursor: pointer;
              "
              :style="{ backgroundColor: data.item.hex }"
              @click="copyToClipboard(data.item.hex)"
              >{{ data.item.hex }}</b
            >
          </template>
          <template #cell(cmyk)="data">
            {{ data.item.c }} / {{ data.item.m }} / {{ data.item.y }} /
            {{ data.item.k }}
          </template>
          <template #cell(rgb)="data">
            {{ data.item.r }} / {{ data.item.g }} / {{ data.item.b }}
          </template>
        </b-table>
      </b-row>
      <b-row style="background-color: #ffffff; position: absolute; bottom: 0; width: 100%; height: 1.2rem; padding: 0 1em; font-size: small;">
        <span style="color: rgb(38, 78, 137); float: left; width: 50%; text-align: left;"><strong>&copy; 2022-{{ year }} <a href="mailto:volf@ov-media.cz">Petr Volf</a> | &copy; 2022-{{ year }} <a href="https://sonydadc.com" target="_blank">SonyDADC</a> | Amount of Pantone colors: {{ pantoneAmount }}</strong></span>
        <span style="color: rgb(38, 78, 137); float: right; width: 50%; text-align: right;"><strong>Internal use only | v{{ version }}(#<a :href="commitPath" target="_blank">{{gitVersion}}</a>)</strong></span>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
const year = new Date().getFullYear()
//import {version} from '../package.json'
const version = require('../package.json').version

export default {
  name: "App",
  data() {
    return {
      metaInfo: {
        title: 'SonyDADC Pantone Matching System v.' + version
      },
      fields: [
        { key: "charged", label: "Surcharge" },
        { key: "system", label: "Pantone system" },
        { key: "dadcname", label: "SonyDADC name" },
        { key: "pantonename", label: "Pantone name" },
        { key: "hex", label: "HEX" },
        { key: "cmyk", label: "CMYK" },
        { key: "rgb", label: "RGB" },
      ],
      filter: null,
      filterOn: ["dadcname"],
      pantones: {},
      dismissSecs: 10,
      dismissCountDown: 0,
      submitButton: "SUBMIT",
      password: "",
      api: "https://api.petrvolf.eu/dadc-color-table",
      year: year,
      version: version,
      gitVersion: process.env.VUE_APP_GIT_HASH,
    };
  },
  methods: {
    fetchPantones() {
      this.submitButton = "LOADING...";
      axios({
        method: "post",
        url: this.api,
        headers: {},
        data: {
          password: this.password,
        },
      }).then((response) => {
        if (response.data.pantonelist) {
          localStorage.password = this.password
          this.pantones = response.data.pantonelist;
          this.$refs["pass-modal"].hide();
        } else {
          localStorage.password = ''
          this.makeErrorToast();
          this.$refs["pass-modal"].show();
          this.submitButton = "SUBMIT";
        }
      });
    },
    copyToClipboard(hexcode) {
      let copy = hexcode.slice(1);
      navigator.clipboard.writeText(copy);
      this.makeToast(hexcode);
    },
    makeToast(hexcode) {
      this.$bvToast.toast(`HEX code ${hexcode} was copied to clipboard!`, {
        autoHideDelay: 3000,
        variant: "success",
        toaster: "b-toaster-top-center",
        solid: true,
        noCloseButton: true,
        appendToast: false,
        toastClass: "text-center",
      });
    },
    makeErrorToast() {
      this.$bvToast.toast(`Wrong password!`, {
        autoHideDelay: 3000,
        variant: "danger",
        toaster: "b-toaster-top-center",
        solid: true,
        noCloseButton: true,
        appendToast: false,
        toastClass: "text-center",
      });
    },
  },
  mounted() {
    if (localStorage.password) {
      this.password = localStorage.password;
      this.fetchPantones()
    } else {
      this.$refs["pass-modal"].show();
    }
  },
  computed: {
    commitPath() {
      return `https://github.com/djfinch/dadc-colour-table/commit/${this.gitVersion}`
    },
    pantoneAmount() {
      return this.pantones.length
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  background-color: #ffffff;
}
a {
  color: rgb(38, 78, 137);
}
</style>
